<template>
  <div class="apply">
    <section class="py-5">
      <div class="container">
        <div class="row justify-content-center mt-5">
          <div class="col-md-10 col-lg-8 col-xl-6">
            <div class="text-center">
              <h1>Let’s summarize</h1>
              <p>Please take a moment to read the summary of information below while we finalize your request</p>
            </div>
            <div class="progress mb-4 d-none">
              <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" :style="{width: progress + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
            <div class="card mb-4 card-grey p14">
              <div class="card-body">
                <ul class="tick-list">
                  <li>You have selected the <strong>Credit Builder Loan</strong> to help rebuild or establish your credit.</li>
                  <li>The <strong>$1,500</strong> loan you are agreeing to will help <strong>boost your credit</strong> by demonstrating responsible loan repayments.</li>
                  <li>While you are not receiving cash as with a typical loan, the exciting part is that you may withdraw the principal amount you pay each month (excluding including interest and fees).</li>
                  <li>This means that for every <strong>$82</strong> you pay monthly, you can withdraw <strong>$63</strong> towards the following month’s loan payment.</li>
                  <li>We report your on-time payments to the credit bureaus.</li>
                </ul>
                Once you consistently repay this loan for <strong>3 months</strong>, you will become eligible for an <strong>additional $1,500 loan</strong>, enabling you to <strong>Hyper-boost</strong> your credit score.
                <div class="text-center mt-5">
                  <button class="btn btn-primary" style="min-height: 54px;" @click="handleClick">{{ sending ? 'Please wait...' : 'Acknowledged. Complete my request!' }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {  
  name: 'Summary',
  title: 'Summary',
  data() { 
    return {
      sending: false,
    }
  },
  methods: {
    ...mapActions(['registerAction']),
    handleClick() {
      this.sending = true;
      this.registerAction({
        name: 'summaryAcknowledgeButton',
        value: '',
        type: 'click',
      });
      setTimeout(() => {
        this.$router.push({ name: 'Approved'});
      }, 2500);
    }
  }
}
</script>
