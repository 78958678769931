<template>
  <HeroSection />
  <div class="about">
    <div id="start">&nbsp;</div>
    <section class="py-5">
      <div class="container">
        <div class="row justify-content-center ">
          <div class="col-md-12 col-lg-9">
            <p>
              Embark Financial is a newly established financial credit-building company that has one focus – to assist you
              in boosting or establishing your credit score. We are not a bank, but rather, a “Fintech” company. Our team
              has decades of experience in the lending business and has determined that there are actually quite simple
              ways of enhancing credit – all without taking on high-interest loans. Even if you pay these loans on time,
              many high-priced lenders don’t even report to the credit bureaus – having little to no effect on your credit
              profile.
            </p>
            <p>Our name is “Embark” because it’s a journey together to enhance your credit. Better credit or newly
              established credit is never a short-term fix in nature, rather, it’s a journey that takes patience, and is
              best to start as soon as possible.</p>
            <p>Embark with us. Often the best path to take is the simplest. We provide you with “simple.”</p>
            <p>Together we will navigate this journey toward a brighter future.</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import HeroSection from '@/components/HeroSection';

export default {
  name: 'About',
  title: 'About',
  components: {
    HeroSection,
  },
}
</script>
