<template>
  <div class="expandable-content">
    <div class="expandable-content-title" @click="active = !active" :class="{ 'active': active }">
      <slot name="title">
        Expandable Title
      </slot>
    </div>
    <div class="expandable-content-body">
      <slot name="content">
        Expandable content
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExpandableContent',
  data() {
    return {
      active: false,
    }
  },
}
</script>

<style scoped>
.expandable-content {
	position: relative;	
}

.expandable-content-title {
	margin-bottom: 5px;
	text-align: left;
	cursor: pointer;
	margin-bottom: 0px;
	padding-right: 25px;
  font-weight: bold;
}

.expandable-content-title:after {
	display: block;
	content: '+';
	color: #222;
	position: absolute;
	right: 0px;
  top: -7px;
	font-weight: 800;
  font-size: 24px;
	-webkit-transition: .15s;
  -moz-transition: .15s;
  -o-transition: .15s;
  transition: .15s;
}

.expandable-content-title.active:after {
	transform: rotate(45deg);
	-webkit-transition: .15s;
  -moz-transition: .15s;
  -o-transition: .15s;
  transition: .15s;
}

.expandable-content-title + div {
	padding-top: 20px;
	display: none;
}

.expandable-content-title.active + div {
	display: block;
  
}
</style>