<template>
  <div class="apply">
    <section class="py-5">
      <div class="container">
        <div class="row justify-content-center mt-5">
          <div class="col-md-10 col-lg-8 col-xl-6">
            <div class="text-center">
              <h1>Create your account</h1>
              <p>Almost there! Create your free Embark account to unlock a world of credit builder loan options and start building your credit today</p>
            </div>
            <div class="progress mb-4">
              <div class="progress-bar progress-bar-striped" role="progressbar" style="width: 10%" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
            <Form
              @submit="onSubmit"
              @invalid-submit="onInvalidSubmit"
              v-slot="{ isSubmitting, errors }"
              :initial-values="initialValues"
              class="mb-4"
              id="create-form-2"
            >
              <div class="card mb-4 card-grey p14">
                <div class="card-body">
                  <h3 class="mb-4 text-center">Legal Name &amp; Address</h3>
                  <div class="row">
                    <div class="col-md-6">
                      <BasicInput
                        name="firstName"
                        id="firstName"
                        label="First Name"
                        wrapperCSS="mb-4 inset-label"
                        :validator="validators.firstName"
                        :invalidSubmit="invalidSubmit"
                      />
                    </div>
                    <div class="col-md-6">
                      <BasicInput
                        name="lastName"
                        id="lastName"
                        label="Last Name"
                        wrapperCSS="mb-4 inset-label"
                        :validator="validators.lastName"
                        :invalidSubmit="invalidSubmit"
                      />
                    </div>
                  </div>
                  <fieldset>
                    <BasicInput
                      name="address"
                      id="address"
                      label="Address"
                      wrapperCSS="mb-4 inset-label"
                      :validator="validators.address"
                      :invalidSubmit="invalidSubmit"
                    />
                    <div class="row">
                      <div class="col-6 col-md-4">
                        <div class="form-group">
                          <MaskedInput
                            name="zip"
                            id="zip"
                            label="Zipcode"
                            type="tel"
                            wrapperCSS="mb-4 inset-label"
                            maxlength="5"
                            v-model="zip"
                            :mask="masks.zip"
                            :validator="validators.zip"
                            :invalidSubmit="invalidSubmit"
                          />
                        </div>
                      </div>
                      <div class="col-6 col-md-4">
                        <div class="form-group">
                          <BasicInput
                            name="state"
                            id="state"
                            label="State"
                            v-model="state"
                            maxlength="2"
                            wrapperCSS="mb-4 inset-label"
                            :validator="validators.state"
                            :invalidSubmit="invalidSubmit"
                          />
                        </div>
                      </div>
                      <div class="col-12 col-md-4">
                        <div class="form-group">
                          <BasicInput
                            name="city"
                            id="city"
                            label="City"
                            v-model="city"
                            wrapperCSS="mb-4 inset-label"
                            :validator="validators.city"
                            :invalidSubmit="invalidSubmit"
                          />
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div class="card mb-4 card-grey p14">
                <div class="card-body">
                  <h3 class="mb-4 text-center">Contact Details</h3>
                  <BasicInput
                    name="email"
                    id="email"
                    label="Email Address"
                    wrapperCSS="mb-4 inset-label"
                    type="email"
                    :validator="validators.email"
                    :invalidSubmit="invalidSubmit"
                  />
                  <MaskedInput
                    name="cellPhone"
                    id="cellPhone"
                    label="Phone"
                    maxlength="12"
                    type="tel"
                    wrapperCSS="mb-4 inset-label"
                    :mask="masks.cellPhone"
                    :validator="validators.cellPhone"
                    :invalidSubmit="invalidSubmit"
                  />
                  <MaskedInput
                    name="dob"
                    id="dob"
                    label="Date of Birth"
                    maxlength="10"
                    wrapperCSS="mb-4 inset-label"
                    :mask="masks.dob"
                    type="tel"
                    :validator="validators.dob"
                    :invalidSubmit="invalidSubmit"
                  />
                  <MaskedInput
                    name="ssn"
                    id="ssn"
                    label="Social Security Number"
                    wrapperCSS="mb-4 inset-label"
                    maxlength="11"
                    :mask="masks.ssn"
                    type="tel"
                    v-model="ssn"
                    autocomplete="off"
                    :validator="validators.ssn"
                    :invalidSubmit="invalidSubmit"
                  />
                </div>
              </div>
              <div class="card mb-4 card-grey p14">
                <div class="card-body">
                  <h3 class="mb-4 text-center">Are you a U.S. citizen or permanent resident?</h3>
                  <p class="text-center">
                    <small>Don’t worry, non-resident aliens (H1B, L1, and Student Visa holders) can get a Credit Builder Account.</small>
                  </p>
                  <div class="d-flex justify-content-center">
                    <label for="residentYes">
                      <input type="radio" id="residentYes" name="resident" value="yes" v-model="resident"> Yes
                    </label>
                    <label for="residentNo" class="ml-3">
                      <input type="radio" id="residentNo" name="resident" value="no" v-model="resident"> No
                    </label>
                  </div>
                </div>
              </div>
              <div class="card mb-4 card-primary">
                <div class="card-body">
                  <h3>Choose a password</h3>
                  <BasicInput
                    name="password"
                    v-model="password"
                    id="password"
                    type="password"
                    label="Password"
                    errorCSS="text-light"
                    :validator="validators.password"
                    :invalidSubmit="invalidSubmit"
                  />
                </div>
              </div>
              <div class="card mb-4 card-grey p14">
                <div class="card-body">
                  <strong>Password requirements</strong><br>
                  <ul class="password-requirements-list">
                    <li :class="{valid: pwLength}">More than 8 characters</li>
                    <li :class="{valid: pwLowercase}">Contains a lowercase letter</li>
                    <li :class="{valid: pwUppercase}">Contains an uppercase (capital) letter</li>
                    <li :class="{valid: pwNumber}">Contains a number</li>
                    <li :class="{valid: pwSpecial}">Contains a special character (e.g. !@$%)</li>
                  </ul>
                </div>
              </div>
              <FormDisclaimer />
              <div class="text-center">
                <button type="submit" class="btn btn-primary" style="min-height: 54px;" :disabled="isSubmitting">{{ !isSubmitting ? 'Next' : 'Loading...'}}</button>
              </div>
              <div class="alert alert-danger mt-4" v-if="invalidSubmit && !isEmpty(errors)">
                <strong>The form contains the following errors</strong>
                <ul class="mt-3">
                  <li v-for="(errorMessage, fieldName) in errors" :key="fieldName">
                    {{ errorMessage }}
                  </li>
                </ul>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { Form } from 'vee-validate';
import * as Yup from 'yup';
import BasicInput from '../components/BasicInput';
import MaskedInput from '../components/MaskedInput';
import FormDisclaimer from '../components/FormDisclaimer';
import { maskDOB, maskPhone, maskSSN, maskState, maskZip } from '../helpers/formatters';
import { getCityState } from '../helpers/util';
import { dateOfBirthValidator, phoneValidator, ssnValidator,
  stateValidator, zipcodeValidator } from '../helpers/validators';

export default {
  name: 'Create2',
  title: 'Create Your Account',
  components: {
    BasicInput,
    Form,
    FormDisclaimer,
    MaskedInput,
  },
  data() {
    return {
      saving: false,
      invalidSubmit: false,
      password: '',
      zip: '',
      city: '',
      state: '',
      resident: 'yes',
      initialValues: { 
        firstName: '',
        lastName: '',
        email: '',
        dob: '',
        zip: '',
        city: '',
        state: '',
        address: '',
        cellPhone: '',
        ssn: '',
        password: '',
      },
      masks: {
        cellPhone: maskPhone,
        dob: maskDOB,
        state: maskState,
        zip: maskZip,
        ssn: maskSSN,
      },
      validators: {
        firstName: Yup.string().required('Please enter your first name').min(2),
        lastName: Yup.string().required('Please enter your last name').min(2),
        email: Yup.string().email('Email address is invalid').required('Please enter your email'),
        zip: zipcodeValidator,
        address: Yup.string().required('Please enter your address').min(2),
        city: Yup.string().required('Please enter your city').min(2),
        state: stateValidator,
        cellPhone: phoneValidator,
        dob: dateOfBirthValidator,
        ssn: ssnValidator,
        password: Yup.string().required('Please enter a password'),
      },
    };
  },
  methods: {
    ...mapActions(['registerAction', 'saveFormData']),
    onSubmit(values) {
      this.registerAction({
        name: 'createFormSubmit',
        value: '1',
        type: 'submit',
      });
      this.saveFormData({
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email.toLowerCase(),
        dob: values.dob,
        zip: values.zip,
        city: values.city,
        state: values.state,
        address: values.address,
        cellPhone: values.cellPhone,
        ssn: values.ssn,
        password: values.password,
        resident: this.resident,
      });
      this.$router.push('verifying');
    },
    onInvalidSubmit() {
      this.invalidSubmit = true;
      this.registerAction({
        name: 'createFormSubmit',
        value: '0',
        type: 'submit',
      });
    },
    isEmpty(obj) {
      return Object.keys(obj).length === 0;
    },
    formatState(state) {
      if (state !== state.toUpperCase()) {
        this.state = state.toUpperCase();
      }
    },
    async setCityState(zip) {
      if (zip.length !== 5) {
        return;
      }
      try {
        const result = await getCityState(zip);
        if (result) {
          this.city = result.city;
          this.state = result.state;
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
  computed: {
    ...mapGetters(['formData']),
    pwLength() {
      return this.password.length > 8;
    },
    pwLowercase() {
      return /[a-z]/.test(this.password);
    },
    pwUppercase() {
      return /[A-Z]/.test(this.password);
    },
    pwNumber() {
      return /\d/.test(this.password);
    },
    pwSpecial() {
      return /[!@#$%^&*(),.?":{}|<>]/.test(this.password);
    },
  },
  watch: {
    zip: 'setCityState',
    state: 'formatState',
  },
  created() {
    this.initialValues.firstName = this.formData.firstName || '';
    this.initialValues.lastName = this.formData.lastName || '';
    this.initialValues.email = this.formData.email || '';
    this.initialValues.dob = this.formData.dob || '';
    this.initialValues.zip = this.formData.zip || '';
    this.initialValues.city = this.formData.city || '';
    this.initialValues.state = this.formData.state || '';
    this.initialValues.address = this.formData.address || '';
    this.initialValues.cellPhone = this.formData.cellPhone || '';
    this.initialValues.ssn = this.formData.ssn || '';
  }
}
</script>
