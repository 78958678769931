<template>
  <div class="apply">
    <section class="py-5">
      <div class="container">
        <div class="row justify-content-center mt-5">
          <div class="col-md-10 col-lg-8 col-xl-6">
            <div class="text-center">
              <h1>Congratulations</h1>
              <p>You have been pre-approved. To proceed and explore your available options, please click on the “Continue” button below.</p>
            </div>
            <div class="progress mb-4">
              <div class="progress-bar progress-bar-striped" role="progressbar" :style="{width: progress + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
            <div class="card mb-4 card-grey p14">
              <div class="card-body">
                <div class="row">
                  <div class="col-12 col-sm-4 d-flex flex-column justify-content-between align-items-center align-items-sm-start mb-3 mb-sm-0">
                    <div class="p12"><strong>Personal Credit Builder Loan</strong></div>
                    <div class="offer-detail-lg">$82<span>/mo</span></div>
                  </div>
                  <div class="col-6 col-sm-3 d-flex flex-column justify-content-between align-items-center align-items-sm-start">
                    <div class="offer-title">Loan Term</div>
                    <div class="offer-detail mb-2">24mo</div>
                  </div>
                  <div class="col-6 col-sm-3 d-flex flex-column justify-content-between align-items-center align-items-sm-start">
                    <div class="offer-title">Loan Amount</div>
                    <div class="offer-detail mb-2">$1500</div>
                  </div>
                  <div class="col-12 col-sm-2 d-flex flex-column justify-content-center align-items-center mt-3 mt-sm-0">
                    <div class="offer-check"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card mb-4 card-grey p14">
              <div class="card-body">
                <expandable-content>
                  <template v-slot:title>
                    Account documentation and disclosures
                  </template>
                  <template v-slot:content>
                    <ul>
                      <li>Document 1</li>
                      <li>Document 2</li>
                      <li>Document 3</li>
                    </ul>
                  </template>
                </expandable-content>
              </div>
            </div>
            <div class="card mb-4 card-grey p14">
              <div class="card-body">
                <expandable-content>
                  <template v-slot:title>
                    Backup Withholding, FATCA, and Beneficiary
                  </template>
                  <template v-slot:content>
                    <fieldset class="mb-4">
                      <strong>Backup withholding</strong>
                      <div class="form-check mb-2">
                        <input class="form-check-input" type="checkbox" value="1" id="backup-widthholding" checked>
                        <label class="form-check-label" for="backup-widthholding">
                          I am not subject to backup withholding either bcause I have not been notified that
                          I am subject to backup withholding as a result of failure to report all interest or
                          dividends , or the Internal Revenue Service has notified me that I am no longer
                          subject to backup withholding.
                        </label>
                      </div>

                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="1" id="backup-widthholding2">
                        <label class="form-check-label" for="backup-widthholding2">
                          I am an exempt recipient under the Internal Revenue Service Regulations.
                        </label>
                      </div>
                    </fieldset>
                    <fieldset class="mb-4">
                      <strong>FATCA Code</strong>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="1" id="FATCA">
                        <label class="form-check-label" for="FATCA">
                          The FATCA code entered in this form (if any) indicating that I am exempt from FATCA
                          reporting is correct.
                        </label>
                      </div>
                    </fieldset>
                    <fieldset>
                      <strong>Beneficiary</strong>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" name="beneficiary" value="1" id="beneficiary" checked>
                        <label class="form-check-label" for="beneficiary">
                          This account will follow the rules of my will or intestacy.
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" name="beneficiary" value="2" id="beneficiary2">
                        <label class="form-check-label" for="beneficiary2">
                          I will designate the beneficiary of this account separately from my will.
                        </label>
                      </div>
                    </fieldset>

                  </template>
                </expandable-content>
              </div>
            </div>
            <div class="text-center">
              <button class="btn btn-primary" style="min-height: 54px;" @click.prevent="goToNext">Continue</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>

import { mapActions } from 'vuex';
import ExpandableContent from '../components/ExpandableContent';

export default {
  name: 'Congratulations',
  title: 'Congratulations',
  components: {
    ExpandableContent
  },
  data() {
    return {
      progress: 90,
    }
  },
  methods: {
    ...mapActions(['registerAction']),
    goToNext() {
      this.registerAction({
        name: 'CongratulationsNext',
        value: '1',
        type: 'click',
      });

      this.$router.push('summary');
    }
  }
}
</script>
