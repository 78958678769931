<template>
  <div class="apply">
    <section class="py-5">
      <div class="container">
        <div class="row justify-content-center mt-5">
          <div class="col-md-10 col-lg-8 col-xl-6">
            <div class="text-center">
              <h1>Thank you for your request</h1>
              <p>We appreciate your interest in our services. Please be assured that a customer service specialist will be reaching out to you soon to discuss next steps</p>
            </div>
            <div class="progress mb-4">
              <div class="progress-bar progress-bar-striped" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
            <div class="mb-4 text-center">
              <img src="/images/thankyou-animation.gif" style="max-width: 250px;">
            </div>
            <div class="text-center">
              They will provide you with more information and explore alternative options to meet your financial needs. Our team is committed to delivering exceptional service and assisting you in any way we can.
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>

export default {
  name: 'Thankyou',
  title: 'Thank You',
}
</script>
