import Api from './api';

export const getCityState = async (zipcode) => {
  if (zipcode.length !== 5) {
    return;
  }

  const endpoint = 'zipcode';
  try {
    const response = await Api.get(`${endpoint}/${zipcode}`);
    if (response.data.found) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const statesList = ['AL','AK','AZ','AR','CA','CO','CT','DE','DC','FL','GA','HI','ID','IL','IN','IA','KS','KY','LA','ME','MD','MA','MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NC','ND','NY','OH','OK','OR','PA','PR','RI','SC','SD','TN','TX','UT','VT','VA','WA','WV','WI','WY'];