import * as Yup from 'yup';
import moment from 'moment';
import Api from './api';
import { getCityState, statesList } from './util';


export const dateOfBirthValidator = Yup.string()
  .required('Please enter your date of birth')
  .matches(
    /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(1\d\d\d|20\d\d)$/,
    'Date of birth must be in the format MM/DD/YYYY'
  )
  .test('age', 'You must be over 18 to apply', (value) => {
    if (!value) return false;
    const checkDate = moment().subtract(18, 'years');
    const checkDate2 = moment().subtract(100, 'years');
    const bd = moment(value, 'MM/DD/YYYY');
    return bd < checkDate && bd > checkDate2;
  });

export const passwordValidator = Yup.string()
  .required('Password is a required field')
  .min(9)
  .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[a-zA-Z\d!@#$%^&*(),.?":{}|<>]{9,}$/,
'Password does not meet the required conditions');

export const phoneValidator = Yup.string()
  .required('Please enter your phone number')
  .matches(/^\d{3}-\d{3}-\d{4}$/, 'Phone must be in the format xxx-xxx-xxxx')
  .test('areacode', 'Oops! It looks like that number is not real.', async (value) => {
    if (value.length <12) return false;
    if (value.substr(0, 1) === '8') {
      return true;
    }

    try {
      const response = await Api.get(`areacode/${value}`);
      if (response.data.found) {
        return true;
      }
      return false;
    } catch (err) {
      return false;
    }
  });

export const zipcodeValidator = Yup.string()
  .required('Please enter your zipcode')
  .test('zipcode', 'Sorry we do not currently process requests from your state.', async (value) => {
    if (value.length <5) return false;
    if (!/^\d{5}$/.test(value)) return false;
    try {
      const response = await getCityState(value);
      if (response.state) {
        return statesList.includes(response.state);
      }
      return false;
    } catch (err) {
      // console.log(err);
      return false;
    }
  });

export const stateValidator = Yup.string()
  .required('Please enter your state')
  .test('state', 'Sorry we do not currently process requests from your state.', async (value) => {
    if (value.length < 2) return false;
    if (!/^[A-Z]{2}$/.test(value)) return false;
    return statesList.includes(value);
  });

export const ssnValidator = Yup.string()
  .required('SSN is a required field')
  .min(11)
  .matches(/^(?!219-09-9999|078-05-1120)(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/,
'The number you entered is not a valid SSN');
