<template>
  <HeroSection />
  <div class="faq">
    <div id="start">&nbsp;</div>
    <section class="py-5 bg-grey">
    <div class="container">
      <div class="row justify-content-center ">
        <div class="col-md-12 col-lg-9">
          <h2 class="text-center mb-5">FAQ</h2>
          <div class="questions">
            <div class="question">
              <div class="question-title">
                <h4>Can I pay off my loan early?</h4>
              </div>
              <div class="question-answer">
                Absolutely! If you decide to close your account before the loan term ends, we authorize the third-party bank to release the loan funds to you, deducting any outstanding payments and the non-refundable account opening fee. For instance, let's say you borrowed a $1200 credit builder loan with a 12-month term and 0% interest (for simplicity). If you choose to cancel the loan after making 10 payments, the third-party bank will disburse the loan amount ($1200) minus the remaining principal (2 payments * $100 principal per payment), resulting in $1000 sent to you.
              </div>
            </div>
            <div class="question">
              <div class="question-title">
                <h4>Are there any late fees?</h4>
              </div>
              <div class="question-answer">
                No need to worry about late fees with us! If a payment is about to become 30 days delinquent, Embark will close the loan to protect your credit and refund the principal amount you've paid so far. We only deduct the accrued interest on the late payment to report the account as closed without delinquency.
              </div>
            </div>
            <div class="question">
              <div class="question-title">
                <h4>What is the account opening fee?</h4>
              </div>
              <div class="question-answer">
                The account opening fee is a one-time, non-refundable charge incurred at the beginning of the loan. It covers the administrative work involved in opening the account on your credit report. By charging this fee, we can offer you an interest rate below 4%. Furthermore, every payment you make helps boost your credit score!
              </div>
            </div>
            <div class="question">
              <div class="question-title">
                <h4>I need funds NOW!</h4>
              </div>
              <div class="question-answer">
                We understand your urgency. If you require immediate funds, we recommend applying for a traditional Embark loan instead of the credit builder loan. Please click here for more information.
              </div>
            </div>
            <div class="question">
              <div class="question-title">
                <h4>How much will my credit score increase?</h4>
              </div>
              <div class="question-answer">
                Determining the exact increase in your credit score is challenging as lenders utilize various credit scoring models. We have no control over how these scores are calculated. However, we can assure you that we faithfully report your payments, which contribute to building your credit history. Lenders rely on this data to evaluate your creditworthiness.
              </div>
            </div>
            <div class="question">
              <div class="question-title">
                <h4>Who receives the payment information?</h4>
              </div>
              <div class="question-answer">
                We report your loan activity to TransUnion, Equifax, and Experian, the major credit bureaus.
              </div>
            </div>
            <div class="question">
              <div class="question-title">
                <h4>What happens to my funds held by the third-party bank?</h4>
              </div>
              <div class="question-answer">
                Your funds remain untouched in the bank account until you complete or cancel your loan. We understand the hesitation in making payments without receiving the funds upfront. That's why we allow you to cancel the loan at any time and receive a pro-rated portion of your principal.
              </div>
            </div>
            <div class="question">
              <div class="question-title">
                <h4>Why choose a credit builder loan over a traditional loan?</h4>
              </div>
              <div class="question-answer">
                Our research indicates that longer and larger loans have a more significant impact on your credit score. Unlike our traditional loan option, our credit builder loan is not designed to be large or long-term. Consider a $1,000 loan with a 190% APR over 12 months. That would accumulate nearly $1,300 in fees! We don't believe in burdening you with excessive costs to build your credit.
              </div>
            </div>
            <div class="question">
              <div class="question-title">
                <h4>Why are my funds given to a third-party bank?</h4>
              </div>
              <div class="question-answer">
                In a traditional loan, a significant portion of your fees is allocated to cover losses from non-paying borrowers. By entrusting your funds to a third-party bank, we eliminate the risk of non-payment, resulting in cost savings for everyone involved.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  </div>
</template>
<script>
import HeroSection from '@/components/HeroSection';
export default {
  name: 'FAQ',
  title: 'FAQ',
  components: {
    HeroSection,
  },
}
</script>
