<template>
  <div class="apply">
    <section class="py-5">
      <div class="container">
        <div class="row justify-content-center mt-5">
          <div class="col-md-10 col-lg-8 col-xl-6">
            <div class="text-center">
              <h1>You're approved</h1>
              <p>Just review and accept the terms to open your account.</p>
            </div>
            <div class="card mb-4 card-grey p14">
              <div class="card-body">
                <div class="d-flex align-items-center justify-content-between">
                  <h3>Your cost today:</h3>
                  <h3>$9</h3>
                </div>
                You are only charged the $9 fee if you choose to accept the Credit Builder Account. This
                fee is non-refundable.
              </div>
            </div>
            <div class="card mb-4 card-grey p14">
              <div class="card-body">
                <expandable-content>
                  <template v-slot:title>
                    Your Credit Builder Account
                  </template>
                  <template v-slot:content>
                    <ul>
                      <li>Today, you pay a $9 non-refundable administrative fee to open the account.</li>
                      <li>We lend you $1500 that is placed into a certificate of deposit bank account ("CD") for  24 months. The CD pays you 0.10% APY†.</li>
                      <li>The loan has an interest rate of 14.70% and a total 15.97% Annual Percentage Rate.</li>
                      <li>You make 24 payments of $82 per month, and your payment history is reported each month to the credit bureaus.</li>
                      <li>On {{ dateOneYearFromNow }}, you've established credit history and your CD matures and unlocks with $725.45***.</li>
                    </ul>
                    <p>*** This assumes a CD that pays you 0.10% APY† over 24 months along with on-time Credit Builder Account payments.</p>
                    <p class="mb-0">APY† – Annual Percentage Yield.</p>
                  </template>
                </expandable-content>
              </div>
            </div>
            <div class="card mb-4 card-grey p14">
              <div class="card-body">
                <h3>Autopay:</h3>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="1" checked id="autopay">
                  <label class="form-check-label" for="autopay">
                    By choosing to enrol in Autopay, I confirm that I have read Embark’s
                    <a href="#" target="_blank">Autopay Terms and Conditions</a>
                  </label>
                </div>
              </div>
            </div>
            <div class="card mb-4 card-primary p14">
              <div class="card-body">
                <strong class="mb-3 d-block">I commit to pay $82/month on-time for 24 months. I will receive $1,500 in:</strong>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="1" checked id="months">
                  <label class="form-check-label" for="months">
                    24 months
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="1" checked id="terms">
                  <label class="form-check-label" for="terms">
                    I’ve read and accept the <a href="#" target="_blank" class="text-white" style="text-decoration: underline;">Terms and Conditions</a>
                  </label>
                </div>
              </div>
            </div>
            <div class="text-center my-4">
              <button class="btn btn-primary" style="min-height: 54px;" @click="handleClick">{{ sending ? 'Please wait...' : 'Open Account' }}</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import ExpandableContent from '../components/ExpandableContent';

export default {  
  name: 'Approved',
  title: 'Approved',
  components: {
    ExpandableContent,
  },
  data() { 
    return {
      sending: false,
    }
  },
  methods: {
    ...mapActions(['registerAction']),
    handleClick() {
      this.sending = true;
      this.registerAction({
        name: 'openAccountButton',
        value: '',
        type: 'click',
      });
      setTimeout(() => {
        this.$router.push({ name: 'Thankyou'});
      }, 2500);
    }
  },
  computed: {
    dateOneYearFromNow() {
      const today = new Date();
      const nextYear = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate());
      return nextYear.toLocaleDateString();
    },
  }
}
</script>
