<template>
  <Field :name="name" v-slot="{ field, errors, errorMessage, meta }" :rules="validator">
    <div class="input-wrapper" :class="[( !meta.valid && meta.touched ) && 'input-wrapper-invalid', (meta.valid && meta.touched ) && 'input-wrapper-valid', wrapperCSS]">
      <label v-if="label" :for="name" :class="labelCSS">{{ label }}</label>
      <input
        v-bind="field"
        class="form-control"
        :id="id"
        :name="name"
        :type="type"
        :maxlength="maxlength"
        :autocomplete="autocomplete"
        v-cleave="mask"
        ref="input"
        :class="[ ( !meta.valid && meta.touched ) && 'form-control_invalid', ( meta.valid && meta.validated ) && 'form-control_valid', inputCSS ]"
      >
      <div class="control-group__input-error pt-1 mb-2" :class="errorCSS">
        <span v-if="!meta.valid && (meta.touched || meta.dirty)">
          {{ errors[0] || errorMessage }}
        </span>
      </div>
    </div>
  </Field>
</template>

<script>
import { Field } from 'vee-validate';
import Cleave from 'cleave.js';

export default {
  name: 'MaskedInput',
  components: {
    Field,
  },
  props: {
    label: String,
    id: String,
    name: String,
    errorCSS: String,
    labelCSS: String,
    inputCSS: String,
    wrapperCSS: String,
    mask: Object,
    validator: Object,
    invalidSubmit: Boolean,
    maxlength: {
      type: Number,
      default: 255,
    },
    autocomplete: {
      type: String,
      default: 'on',
    },
    type: {
      type: String,
      default: 'text',
    },
  },
  directives: {
    cleave: {
      mounted(el, binding) {
        el.cleave = new Cleave(el, binding.value || {});
      },
      updated(el) {
        const newValue = el.cleave.getFormattedValue();
        if (newValue !== el.value) {
          el.value = newValue;
          const event = new Event('input', { bubbles: true });
          el.dispatchEvent(event);
        }
      },
      unmounted(el) {
        if (el.cleave) {
          el.cleave.destroy();
          delete el.cleave;
        }
      },
    }
  },
};
</script>
