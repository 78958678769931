<template>
  <HeroSection />
  <div class="creditbasic">
    <div id="start"></div>
    <section class="py-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col">
            <h4>Credit Basics</h4>
            <p class="mt-4">Credit can be a valuable tool to help you meet your financial goals.
              That’s why it’s important to understand what credit is, how to manage it, and what you stand to gain
              from establishing and maintaining good credit.
            </p>
            <b>1. What is credit?</b>
            <p>Credit is your ability to obtain the goods or services you want now by promising to pay for them
              later. Your ability to obtain credit is based on your credit history.</p>
            <b>2. How does credit work? </b>
            <p>Lenders extend credit by lending you money at an agreed-upon amount, rate, and payment term. When you
              borrow, you begin to establish a credit history: this is a record of your credit accounts, your
              payment history, and the details of how you manage each account. This information is gathered by the
              three national credit reporting agencies (Equifax, TransUnion, and Experian), and the data is used
              to calculate your credit scores. To understand what makes up your credit score, review how credit
              scores are calculated.
            </p>
            <b>3. What are the benefits of good credit?</b>
            <p>Good credit can give you access to more borrowing options, such as paying for a car, a home, or an
              education at the best possible interest rate or terms. In addition, employers, insurance companies,
              landlords, cell phone providers, and more can use your credit history when they make decisions about
              you.
            </p>
          </div>
          <div class="col text-center">
            <img src="images/score-chart.png" alt="">
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import HeroSection from '@/components/HeroSection';
export default {
  name: 'CreditBasic',
  title: 'Credit Basics',
  components: {
    HeroSection,
  },
}
</script>


