<template>
  <div class="mb-4 p14 text-center">
    By proceeding with your request, you consent and agree to Embark.credit’s
    <span class="text-nowrap"><a href="docs/privacy-policy.pdf" target="_blank">Privacy Policy</a></span>
    and <span class="text-nowrap"><a href="docs/terms-and-conditions.pdf" target="_blank">Terms
    and Conditions</a></span> and acknowledge that you are able to view and agree to the
    <a href="docs/consent-to-the-use-of-electronic-documents-and-signatures-disclosure.pdf">Consent
    to the use of Electronic Documents and Signatures Disclosure.</a>
  </div>
</template>

<script>
export default {
  name: 'FormDisclaimer',
}
</script>
