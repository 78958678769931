<template>
  <HeroSection />
  <div class="process">
    <div id="start">&nbsp;</div>
    <section class="py-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10">
            <div class="row justify-content-center">
              <div class="col-md-4 mb-5 border-left pl-4 pr-5">
                <h4 class="yl process-title wa">Step 1</h4>
                <ol class="process-list">
                  <li>Fill out a short application for our $1,000 loan</li>
                  <li> Review your loan payback schedule. If its acceptable, you sign the loan documents</li>
                  <li>Once we have confirmed your identity, you will then be approved for a $1,000 loan</li>
                </ol>
              </div>
              <div class="col-md-4 mb-5 border-left pl-4 pr-5">
                <h4 class="yl process-title wa">Step 2</h4>
                <ol class="process-list">
                  <li>We open your 2 bank Accounts –savings and a restricted loan. The restricted one helps the bank
                    ensure that your loan will be paid back
                  </li>
                  <li>Provide us with your bank account information, and we will help make certain your loan
                    repayments are made on time
                  </li>
                </ol>
              </div>
              <div class="col-md-4 mb-5 border-left pl-4 pr-5">
                <h4 class="yl process-title wa">Step 3</h4>
                <ol class="process-list">
                  <li>As you make your monthly on-time payments, the bank will allow you to withdraw the amount you
                    paid to repay your loan. (That’s Right!)
                  </li>
                  <li>We will report your on-time payments to the Credit Bureaus</li>
                  <li>Your on-time payments over time will help to boost your credit score</li>
                </ol>
              </div>
              <div class="col-md-5 mb-5">
                <h4 class="yl process-title">Extra Bonus</h4>
                <p>With only 3 months of on-time payments, you may qualify for a second $1,000 loan - further enabling
                  you to boost your credit profile</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="py-5 bg-grey">
      <div class="container">
        <div class="text-center mb-5">
          <h4>How does the Credit Builder Loan (CBL) impact my credit score?</h4>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-6 mb-4 mb-md-0">
            <div class="text-center">
              <img src="images/process-icon-1.png" alt="icon" class="process-icon">
            </div>
            <h3 class="text-center yl mb-4">Your credit report</h3>
            <p>Your credit report acts as a report card, documenting your credit payment history like credit card payments,
              mortgages, student loans, and other forms of credit. Embark reports all loan information to shape your credit
              history. Understanding your credit report is crucial, as it reflects your financial behavior and
              creditworthiness to lenders and creditors. A <router-link to="credit_basic.html">positive credit history</router-link>
              increases the likelihood of obtaining future credit with favorable terms and rates.
            </p>
          </div>
          <div class="col-md-6">
            <div class="text-center">
              <img src="images/process-icon-2.png" alt="icon" class="process-icon">
            </div>
            <h3 class="text-center yl mb-4">Your credit score</h3>
            <p>Your credit score is like a grade point average (GPA) for your finances. It reflects your overall financial
              health. Just as bad grades can lower your GPA, late or defaulted payments can negatively impact your credit
              score. The Credit Empowerment Loan's effect on your credit score depends on what's in your credit report. It's
              important to understand that your credit report shows your credit history and how you manage your finances.
              Taking proactive steps to improve your credit report can have a positive impact on your 
              <router-link to="credit_basic.html">credit score</router-link>. By understanding how your credit report and credit score work
              together, you can make better financial decisions and improve your overall financial well-being.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="py-5 ">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-6">
            <div class="text-center">
              <img src="images/process-icon-3.png" alt="icon" class="process-icon">
            </div>
            <h3 class="text-center yl mb-4">Accepting the Credit Empowerment loan</h3>
            <p>When you receive a loan from Embark, it marks the opening of a new credit line, which is reported to the credit
              bureaus. Typically, this has a neutral or slightly negative effect on your credit reports. Opening a new credit line
              can have a neutral impact as it demonstrates your creditworthiness. However, it may slightly influence your credit
              scores as it utilizes your available credit. It's important to note that everyone has a finite amount of credit
              connected to their income. Lenders consider your income-to-debt ratio when assessing your creditworthiness. If you
              already have loans, lenders might offer lower credit limits to ensure responsible repayment.
            </p>
          </div>
          <div class="col-md-6">
            <div class="text-center">
              <img src="images/process-icon-4.png" alt="icon" class="process-icon">
            </div>
            <h3 class="text-center yl mb-4">Repaying your loan back</h3>
            <p>Making timely payments is the most important aspect of establishing good credit with Embark. It shows other lenders
              that you are responsible and capable of fulfilling loan agreements. To illustrate this further, let's consider
              it from the perspective of a mortgage lender. Mortgages are long-term loans that require monthly payments over many
              years. Lenders need assurance that you will meet your obligations. Since many people have limited experience with
              mortgages, lenders evaluate your payment history on other credit accounts to assess your reliability. This applies to
              credit card companies and auto lenders as well. Your payment history significantly influences your credit score,
              making it the most crucial factor in determining your creditworthiness
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="py-5 bg-grey">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-5 mb-5 mb-lg-0">
            <img src="images/embark-girl-headphones.jpg" alt="Girl wearing headphones" class="subhero-img">
          </div>
          <div class="col-md-6">
            <p><b>Now that your loan is paid off, what happens</b></p>
            <p>Once you have fully paid off your Credit Empowerment Loan, we will report it as "Paid Off." While it may seem
              beneficial to have a loan marked as paid off, it's important to understand that closing an installment loan can be
              viewed similarly by credit bureaus. Consequently, after paying off your loan, there might be a slight decrease in
              your credit score as you have one less active account on your credit report. However, this temporary decrease should
              not discourage you from making timely payments and completing your loan. Your consistent payment history will still
              positively impact your credit report, indicating to other lenders that you are a trustworthy borrower. This positive
              influence will remain on your credit report for up to 10 years, even if your loan is considered a "closed account."</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import HeroSection from '@/components/HeroSection';
export default {
  name: 'Process',
  title: 'Process',
  components: {
    HeroSection,
  },
}
</script>
