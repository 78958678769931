<template>
  <div class="create">
    <section class="py-5">
      <div class="container">
        <div class="row justify-content-center mt-5">
          <div class="col-md-10 col-lg-8 col-xl-6">
            <div class="text-center">
              <h1>Create your account</h1>
              <p>Almost there! Create your free Embark account to unlock a world of credit builder loan options and start building your credit today</p>
            </div>
            <div class="progress mb-4">
              <div class="progress-bar progress-bar-striped" role="progressbar" style="width: 10%" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
            <Form
              @submit="onSubmit"
              @invalid-submit="onInvalidSubmit"
              :validation-schema="validationSchema"
              v-slot="{ isSubmitting }"
              :initial-values="initialValues"
              class="mb-4"
              id="create-form-1"
            >
              <div class="card mb-4 card-primary">
                <div class="card-body">
                  <BasicInput
                    name="email"
                    v-model="email"
                    id="email"
                    type="email"
                    label="Email Address"
                    errorCSS="text-light"
                  />
                  <BasicInput
                    name="password"
                    v-model="password"
                    id="password"
                    type="password"
                    label="Password"
                    errorCSS="text-light"
                  />
                </div>
              </div>
              <div class="card mb-4 card-grey p14">
                <div class="card-body">
                  <strong>Password requirements</strong><br>
                  <ul class="password-requirements-list">
                    <li :class="{valid: pwLength}">More than 8 characters</li>
                    <li :class="{valid: pwLowercase}">Contains a lowercase letter</li>
                    <li :class="{valid: pwUppercase}">Contains an uppercase (capital) letter</li>
                    <li :class="{valid: pwNumber}">Contains a number</li>
                    <li :class="{valid: pwSpecial}">Contains a special character (e.g. !@$%)</li>
                  </ul>
                </div>
              </div>
              <div class="card mb-4 card-grey p14">
                <div class="card-body">
                  <h3 class="mb-4 text-center">Are you a U.S. citizen or permanent resident?</h3>
                  <p class="text-center">
                    <small>Don’t worry, non-resident aliens (H1B, L1, and Student Visa holders) can get a Credit Builder Account.</small>
                  </p>
                  <div class="d-flex justify-content-center">
                    <label for="residentYes">
                      <input type="radio" id="residentYes" name="resident" value="yes" v-model="resident"> Yes
                    </label>
                    <label for="residentNo" class="ml-3">
                      <input type="radio" id="residentNo" name="resident" value="no" v-model="resident"> No
                    </label>
                  </div>
                </div>
              </div>
              <FormDisclaimer />
              <div class="text-center">
                <button type="submit" class="btn btn-primary" style="min-height: 54px;" :disabled="isSubmitting">{{ !isSubmitting ? 'Next' : 'Loading...'}}</button>
              </div>
              <div class="alert alert-danger mt-4" v-if="invalidSubmit">
                The form contains errors
              </div>
            </Form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import BasicInput from '../components/BasicInput';
import FormDisclaimer from '../components/FormDisclaimer';
import { passwordValidator } from '../helpers/validators';

export default {
  name: 'Create',
  title: 'Create Your Account',
  components: {
    BasicInput,
    FormDisclaimer,
    Form,
  },
  data() {
    return {
      validationAttempt: false,
      saving: false,
      invalidSubmit: false,
      email: '',
      password: '',
      resident: 'yes',
      initialValues: { email: '', password: '' },
    };
  },
  methods: {
    ...mapActions(['saveFormData']),
    onSubmit() {
      this.registerAction({
        name: 'createFormSubmit',
        value: '1',
        type: 'submit',
      });
      this.saveFormData({
        email: this.email.toLowerCase(),
        password: this.password,
        resident: this.resident,
      });
      this.$router.push('verifying');
    },
    onInvalidSubmit() {
      this.invalidSubmit = true;
      this.registerAction({
        name: 'createFormSubmit',
        value: '0',
        type: 'submit',
      });
    },
  },
  computed: {
    ...mapGetters(['formData']),
    validationSchema() {
      return yup.object({
        email: yup.string().email('Email address is invalid').required('Please enter your email'),
        password: passwordValidator,
      });
    },
    pwLength() {
      return this.password.length > 8;
    },
    pwLowercase() {
      return /[a-z]/.test(this.password);
    },
    pwUppercase() {
      return /[A-Z]/.test(this.password);
    },
    pwNumber() {
      return /\d/.test(this.password);
    },
    pwSpecial() {
      return /[!@#$%^&*(),.?":{}|<>]/.test(this.password);
    }

  },
  mounted() {
    this.email = this.formData.email || '';
    this.initialValues.email = this.formData.email || '';
  }
}
</script>
