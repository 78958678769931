<template>
  <HeroSection />
  <div class="businessloans">
    <div id="start"></div>
    <section class="py-5">
      <div class="container">
        <div class="row justify-content-center mt-5">
          <div class="col-md-12 col-lg-6">
            <div class="text-center">
              <h1>Business Credit Empowerment Loan</h1>
              <p>We are in the process shortly of launching the business Credit Empowerment Loan to provide you with credit building for your business.
                Please check back with us soon. If you would like us to contact you once we are live, please provide us your email:</p>
            </div>
            <Form
              @submit="onSubmit"
              @invalid-submit="onInvalidSubmit"
              :validation-schema="validationSchema"
              v-slot="{ isSubmitting }"
              :initial-values="initialValues"
              class="mb-4"
              id="business-loans-form"
            >
              <div class="card mb-4">
                <div class="card-body">
                  <BasicInput
                    name="email"
                    v-model="email"
                    id="email"
                    type="email"
                    label="Email Address"
                  />
                </div>
              </div>
              <div class="text-center">
                <button type="submit" class="btn btn-primary" style="min-height: 54px;" :disabled="isSubmitting">{{ !isSubmitting ? 'Next' : 'Loading...'}}</button>
              </div>
              <div class="alert alert-danger mt-4" v-if="invalidSubmit">
                The form contains errors
              </div>
            </Form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { Form } from 'vee-validate';
import * as yup from 'yup';
import BasicInput from '../components/BasicInput';
import HeroSection from '@/components/HeroSection';

export default {
  name: 'Business Loans',
  title: 'Business Credit Empowerment Loan',
  components: {
    BasicInput,
    Form,
    HeroSection,
  },
  data() {
    return {
      validationAttempt: false,
      saving: false,
      invalidSubmit: false,
      email: '',
      initialValues: { email: '' },
    };
  },
  methods: {
    onSubmit() {
      this.registerAction({
        name: 'businessLoansFormSubmit',
        value: '1',
        type: 'submit',
      });
      this.$router.push('/');
    },
    onInvalidSubmit() {
      this.invalidSubmit = true;
      this.registerAction({
        name: 'businessLoansFormSubmit',
        value: '0',
        type: 'submit',
      });
    },
  },
  computed: {
    validationSchema() {
      return yup.object({
        email: yup.string().email('Email address is invalid').required('Please enter your email'),
      });
    },
  },
}
</script>
