<template>
  <div class="apply">
    <section class="py-5">
      <div class="container">
        <div class="row justify-content-center mt-5">
          <div class="col-md-10 col-lg-8 col-xl-6">
            <div class="text-center">
              <h1>Verifying your eligibility</h1>
              <p>We are doing some behind the scenes work to assess your request. It will just take a few seconds and we'll automatically move on to the next step</p>
            </div>
            <div v-if="errorMessage" class="text-center">
              <div class="alert alert-danger w-100 mt-4">
                There was an error submitting your request. Please click below to try again.
              </div>
              <button class="btn btn-primary" @click="resubmit" :disabled="sending" id="form-resubmit">
                {{sending ? 'Sending Your Request...' : 'Try Again'}}
              </button>
            </div>
            <div v-else>
              <div class="progress">
                <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" :style="{width: progress + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <img src="/images/verifying-animation.gif" class="img-fluid">
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'Verifying',
  title: 'Verifying Your Eligibility',
  data() {
    return {
      progress: 40,
      errorMessage: false,
      sending: false,
      exitPath: '/',
      forcedLeaving: false,
      leaving: false,
    };
  },
  methods: {
    ...mapActions(['sendLeadCreditBuilder']),
    submitData() {
      this.sendLeadCreditBuilder().then((response) => {
        this.processResponse(response);
      }).catch(() => {
        // There has been an error show error
        this.sending = false;
        this.errorMessage = true;
      });
    },
    processResponse(response) {
      window.onbeforeunload = null;
      this.sending = false;
      this.progress = 99;
      switch (response.status) {
      case 'accepted':
        this.$router.push('congratulations');
        break;
      case 'rejected':
        this.$router.push('congratulations');
        break;
      case 'error':
        // Handle Error
        this.errorMessage = true;
        this.sending = false;
        break;
      default:
        // Catch All Error
        this.errorMessage = true;
        this.sending = false;
        break;
      }
    },
    forceLeave() {
      this.forcedLeaving = true;
      this.$router.push('congratulations');
    },
    resubmit() {
      this.sending = true;
      this.errorMessage = false;
      this.progress = 0;
      this.submitData();
    },
    setProgress() {
      const { progress } = this;
      switch (true) {
      case progress < 50:
        this.progress += Math.floor(Math.random() * 7) + 3;
        break;
      case progress < 90:
        this.progress += Math.floor(Math.random() * 4) + 2;
        break;
      case progress < 97:
        this.progress += 2;
        break;
      default:
        this.progress = 99;
      }
    },
  },
  mounted() {
    setTimeout(this.setProgress, 1000);
    setInterval(this.setProgress, 2000);
    this.sending = true;
    this.submitData();
    // eslint-disable-next-line func-names
    // window.onbeforeunload = function () {
    //   return 'Are you sure you want to close? Closing the window now will interfere with your search.';
    // };
  },
  // beforeRouteLeave(to, from, next) {
  //   if (this.forcedLeaving) {
  //     next();
  //   } else if (this.sending) {
  //     this.leaving = true;
  //     this.exitPath = to.path;
  //     next(false);
  //   } else {
  //     next();
  //   }
  // },
}
</script>
